<template>
  <div>
    <component-layout title="Company Setting">

      <vue-table
        :http="table.http"
        itemName="company-setting"
        :url="table.url"
        :headingTransformer="table.headingTransformer"
        :except="table.except"
        :html="table.html"
        :additionalColumns="table.additionalColumns"
        :additionalColumnsTransformer="table.additionalColumnsTransformer"
        :valueTransformer="table.valueTransformer"
      ></vue-table>
    </component-layout>
  </div>
</template>
<script>
import util from "@/util/util";
import action from "@/components/CompanySetting/action.vue";
import vueTable from "@/components/shared/vue-table.vue";
import ComponentLayout from "@/components/component-layout.vue";

export default {
  components: { vueTable, ComponentLayout },
  data() {
    return {
      util,
      table: {
        http: util.http,
        url: "company-setting",
        html: ["status", "Action"],
        except: ["id", "created_at", "updated_at", "deleted_at"],
        additionalColumns: ["Action"],
        headingTransformer: function(val) {
          return util.camelCase(val);
        },
        valueTransformer: function() {
          return {
            status: (row) => {
              return [
                {
                  item:
                    '<span style="color:white;padding:0.4rem" class="badge ' +
                    (row.status == 0 ? "new badge red" : "new badge green") +
                    '">' +
                    (row.status == 0 ? "Not Active" : "Active") +
                    "</span>",
                  handler: () => null,
                },
              ];
            },
          };
        },
        additionalColumnsTransformer: function() {
          return {
            Action: (row) => {
              return [
                {
                  comp: action,
                  prop: {
                    edit: {
                      name: "CompanySettingEdit",
                      params: {
                        id: row.id,
                      },
                    },
                  },
                },
              ];
            },
          };
        },
      },
    };
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>
